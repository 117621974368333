ion-card-content.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 2em;
    background-color: var(--ion-color-white);
    border: 0;
    border-collapse: collapse;
    box-shadow: none;
}

ion-card-content.table ion-col {
    text-align: left;
    padding: 1em;
    vertical-align: top;
    border: inherit;
}

ion-card-content.table ion-col.ion-no-padding {
    padding: 0 0 1em;
}

ion-card-content.table ion-row:first-child {
    border-bottom: 1px solid #e1e1e1;
}

ion-card-content.table ion-row:first-child ion-col,
ion-card-content.table ion-row.footer ion-col {
    font-weight: 400;
    color: var(--ion-color-primary);
    vertical-align: bottom;
}

ion-card-content.table ion-row:first-child {
    border-top: 0;
}

ion-card-content.table ion-row:first-child ion-row+.table ion-row:first-child ion-row {
    border-top: 1px solid #dedede;
}

ion-card-content.table caption {
    opacity: 0.5;
    font-size: 0.8em;
    text-transform: uppercase;
    font-style: italic;
    text-align: left;
    line-height: 2;
    margin: 1em 0;
}

ion-card-content.table .table-header-title {
    font-size: 1.4em;
}

ion-card-content.table .table-header-subtitle {
    font-size: 1em;
}

ion-card-content.table-bordered ion-row:first-child {
    border-bottom-width: 2px;
}

ion-card-content.table-bordered ion-row {
    border-bottom: 1px solid #e1e1e1;
}

ion-card-content.table-bordered ion-row.footer {
    border-top: 2px solid #e1e1e1;
    border-bottom: none;
}

ion-card-content.table-condensed ion-row,
ion-card-content.table-condensed ion-row ion-col {
    padding: 0.8em;
}

ion-card-content.table-striped ion-row:nth-child(odd) {
    background-color: #f6f6f6;
}

ion-card-content.table-hover ion-row {
    transition: background-color 0.3s ease, opacity 0.3s ease;
}

ion-card-content.table-hover ion-row:hover {
    background-color: #e7e7e7;
}