.table {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
    width: 100%;
    max-width: 100%;
    margin-bottom: 2em;
    background-color: var(--ion-color-white);
    border: 0;
    border-collapse: collapse;
}

ion-card table.table {
    box-shadow: none;
}

.table th,
.table td {
    text-align: left;
    padding: 1em;
    vertical-align: top;
    border: inherit;
}

.table thead tr {
    border-bottom: 1px solid var(--ion-color-light);
}

.table thead th,
.table tfoot tr {
    font-weight: 400;
    color: var(--ion-color-primary);
    vertical-align: bottom;
}

.table caption+thead tr:first-child,
.table colgroup+thead tr:first-child,
.table thead:first-child tr:first-child {
    border-top: 0;
}

.table caption+thead tbody+.table caption+thead tbody,
.table colgroup+thead tbody+.table colgroup+thead tbody,
.table thead:first-child tbody+.table thead:first-child tbody {
    border-top: 1px solid var(--ion-color-light);
}

.table caption {
    opacity: 0.5;
    font-size: 0.8em;
    text-transform: uppercase;
    font-style: italic;
    text-align: left;
    line-height: 2;
    margin: 1em 0;
}

.table-header-title {
    font-size: 1.4em;
}

.table-header-subtitle {
    font-size: 1em;
}

.table-bordered thead tr {
    border-bottom-width: 2px;
}

.table-bordered tr {
    border-bottom: 1px solid var(--ion-color-light);
}

.table-bordered tfoot tr {
    border-top: 2px solid var(--ion-color-light);
    border-bottom: none;
}

.table-condensed tr,
.table-condensed th,
.table-condensed td {
    padding: 0.8em;
}

.table-striped tbody tr:nth-child(odd) {
    background-color: var(--ion-color-medium);
}

.table-hover tbody tr {
    transition: background-color 0.3s ease, opacity 0.3s ease;
}

.table-hover tbody tr:hover {
    background-color: var(--ion-color-light);
}

@media screen and (max-width: 768px) {
    .table-responsive-vertical .table {
        margin-bottom: 0;
        background-color: transparent;
    }
    .table-responsive-vertical .table thead,
    .table-responsive-vertical .table tfoot {
        display: none;
    }
    .table-responsive-vertical .table tbody {
        display: block;
    }
    .table-responsive-vertical .table tbody tr {
        display: block;
        border: 1px solid #e1e1e1;
        border-radius: 2px;
        margin-bottom: 1.6em;
    }
    .table-responsive-vertical .table tbody tr td {
        background-color: #fff;
        display: block;
        text-align: right;
    }
    .table-responsive-vertical .table tbody tr td[data-title]:before {
        content: attr(data-title);
        float: left;
        font-size: inherit;
        font-weight: 400;
        color: var(--ion-color-dark);
    }
}