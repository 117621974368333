/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "./theme/material-table.css";
@import "./theme/grid-table.css";
@import "./theme/variables.scss";

.account-page {
    margin-top: 5rem;
}

.ion-text-underline {
    text-decoration: underline;
}

div.full {
    width: 100%;
}

ion-modal.fullscreen {
    --width: 100%;
    --height: 100%;
    --border-radius: 0;
}

ion-modal.halfscreen {
    --width: 50%;
    --height: 90%;
}

ion-item[hidden] {
    display: none;
}

hr {
    border-color: var(--ion-color-primary);
    background-color: var(--ion-color-primary);
}

.scan-settings {
    .alert-button-group {
        padding: 0 2rem;
        button.scan-settings-button {
            margin: 0 auto 1rem;
            border: 2px solid var(--ion-color-primary);
            border-radius: 2rem;
            display: block;
            width: 100%;
            .alert-button-inner {
                justify-content: center;
            }
        }
    }
}

.table-responsive {
    overflow: scroll;
    ion-row {
        flex-wrap: nowrap;
    }
}

ngx-qrcode {
    max-width: 240px;
    margin: 0 auto;
}

.img-id {
    bottom: 6px;
    font-family: 'Libre Barcode 39', cursive;
    margin: 0;
    font-size: 18pt;
}

.flex-flow-column {
    flex-flow: column;
    gap: 10px;
}

ion-toggle {
    --background-checked: #28b57087;
}

ion-segment-button {
    min-width: auto;
}

ion-progress-bar {
    height: 1.5rem;
    background: #f2f2f2;
}

.ion-full-width {
    width: 100% !important;
    ion-select {
        width: 100%;
    }
}

.item-label-floating.item-has-placeholder.sc-ion-input-ios-h.has-value,
.item-label-floating.item-has-placeholder .sc-ion-input-ios-h.has-value,
.item-label-floating.item-has-placeholder:not(.item-has-value) .sc-ion-input-md-h.has-value {
    opacity: 1;
}

.logo-container {
    width: 100%;
    background: var(--ion-color-light);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    .logo {
        max-width: 13rem;
    }
}

ion-menu a {
    text-decoration: none;
}

ion-label sup {
    color: red;
    font-weight: bold;
    margin-left: 3px;
    font-size: 1.2em;
}

.ion-full-width.addressfield,
.ion-full-width.array,
.ion-full-width.enddate_get,
.ion-full-width.date {
    margin-top: 1.5rem;
}

div.radio ion-list ion-item {
    margin: 0;
}

ion-segment-button.false,
ion-card.false {
    display: none;
}

.item.sc-ion-label-ios-h,
.item .sc-ion-label-ios-h,
.label-floating.sc-ion-label-ios-h,
.item.sc-ion-label-md-h,
.item .sc-ion-label-md-h,
.item-input.sc-ion-label-md-h,
.item-input .sc-ion-label-md-h {
    max-width: fit-content;
    overflow: initial;
}

.logo-header {
    display: flex;
    gap: 10px;
}

.label-justify {
    display: flex;
    justify-content: space-between;
    -webkit-box-pack: justify;
}

.form-item {
    color: var(--ion-card-form-item);
    margin-bottom: 1.5rem;
    ion-input,
    ion-textarea,
    ion-select {
        border: .1rem solid;
        border-radius: 6px;
        margin: 8px 0;
        --padding: 3px 8px;
        --padding-start: 12px;
        --padding-end: 12px;
    }
}

div.radio ion-list ion-item {
    margin: 0;
}

ion-segment-button.false,
ion-card.false {
    display: none;
}

.item.sc-ion-label-ios-h,
.item .sc-ion-label-ios-h,
.label-floating.sc-ion-label-ios-h,
.item.sc-ion-label-md-h,
.item .sc-ion-label-md-h,
.item-input.sc-ion-label-md-h,
.item-input .sc-ion-label-md-h {
    max-width: fit-content;
    overflow: initial;
}

@media print {
    .ion-hide-print {
        display: none !important;
    }
}

.ion-border {
    margin-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 8px;
    border: .07rem solid;
    padding: 1.5rem .5rem;
}

ion-icon.checkInIcon {
    font-size: 8rem;
    cursor: pointer;
}

ion-list.slot-start-round {
    .avatar-wrapper {
        margin-inline: 10px;
        margin-top: 2px;
        margin-bottom: 2px;
        display: flex;
        flex-flow: column;
        gap: 5px;
        position: relative;
        ion-avatar {
            width: 55px;
            height: 55px;
        }
        ion-badge {
            position: absolute;
            right: -10px;
            bottom: -10px;
        }
    }
    ion-avatar.icon {
        width: 55px;
        height: 55px;
        background: var(--ion-avatar-background);
        padding: 13px;
        margin-inline: 10px 10px;
        border-radius: 35%;
        ion-img {
            border-radius: 0;
        }
        ion-img::part(image) {
            object-fit: scale-down;
        }
    }
    ion-icon {
        background: var(--ion-icon-background);
        padding: 13px;
        border-radius: 35%;
    }
    ion-img {
        max-width: 30px;
        height: auto;
    }
}

ion-item-group.item-separate,
ion-list.item-separate {
    ion-item-divider {
        background: none;
        color: var(--ion-color-dark);
        h1 {
            font-weight: bold;
        }
        h2 {
            color: var(--ion-color-medium);
        }
        h3 {
            color: var(--ion-color-medium-shade);
            text-transform: uppercase;
            font-weight: bold;
            font-size: 14px;
        }
    }
    background: none;
    ion-list-header {
        background: none;
    }
    ion-item {
        margin: 15px 15px;
        box-shadow: 0px 2px 15px 0px var(--ion-item-box-shadow);
        border-radius: 25px;
        ion-label {
            p {
                font-weight: bold;
            }
        }
    }
    ion-item::part(native) {
        padding: 10px 0px;
    }
    .avatar-wrapper {
        margin-inline: 10px;
        margin-top: 2px;
        margin-bottom: 2px;
        display: flex;
        flex-flow: column;
        gap: 5px;
        position: relative;
        ion-avatar {
            width: 55px;
            height: 55px;
        }
        ion-badge {
            position: absolute;
            right: -10px;
            bottom: -10px;
        }
    }
    ion-avatar.icon {
        width: 55px;
        height: 55px;
        background: var(--ion-avatar-background);
        padding: 13px;
        margin-inline: 10px 10px;
        border-radius: 35%;
        ion-img {
            border-radius: 0;
        }
        ion-img::part(image) {
            object-fit: scale-down;
        }
    }
    ion-icon {
        background: var(--ion-icon-background);
        padding: 13px;
        margin-inline: 10px 10px;
        border-radius: 35%;
        font-size: 29px;
        margin-top: 2px;
        margin-bottom: 2px;
        color: var(--ion-color-dark);
    }
    ion-img {
        max-width: 30px;
        height: auto;
    }
    ion-badge {
        font-size: 1rem;
        color: var(--ion-color-medium);
        --background: var(--ion-badge-background);
        margin-inline-end: 10px;
    }
    .ion-item-date {
        margin-inline-start: 15px;
        margin-inline-end: 0px;
    }
}

.item-separate.item-radios {
    ion-radio {
        margin-inline: 15px;
    }
}